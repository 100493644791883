const video = document.getElementById('loopVideo');

// Play the video in fullscreen when it's clicked
video.addEventListener('click', () => {
  if (video.paused) {
    video.play();
  } else {
    video.pause();
  }
});

// Add event listener for the video end to restart it
video.addEventListener('ended', () => {
  video.play();
});

// Add event listener for fullscreen change
document.addEventListener('fullscreenchange', () => {
  if (!document.fullscreenElement) {
    video.pause(); // Pause the video when exiting fullscreen
  }
});

// Function to toggle fullscreen
function toggleFullscreen() {
  if (!document.fullscreenElement) {
    video.requestFullscreen().catch(err => {
      console.error(`Fullscreen error: ${err.message}`);
    });
  } else {
    document.exitFullscreen();
  }
}

// Toggle fullscreen when pressing the space key
document.addEventListener('keydown', (event) => {
  if (event.code === 'Space') {
    toggleFullscreen();
  }
});
